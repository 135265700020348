<template>
  <div>
    <s-cabecalho />

    <section id="identificar">
      <div class="container">
        <div class="row">
          <div class="col-1-1">
            <h1>Identifique a sua Empresa</h1>
          </div>
        </div>

        <form @submit.prevent="verificar">
          <div class="row">
            <div class="col-1-1 col-md-3-9 offset-md-3-9 padding-x1">
              <div class="form-floating">
                <the-mask
                  :mask="['##.###.###.###.#', '##.###.###/####-##']"
                  class="form-control"
                  id="cnpj"
                  placeholder=""
                  :disabled="loading"
                  required
                  v-model="campos.cnpj"
                />

                <label for="floatingPassword">Digite o seu CNPJ ou Número do Cadastur</label>
              </div>

              <div id="passwordHelpBlock" class="form-text">
                Informe o seu CNPJ ou Número do Cadastur para identificarmos você e fazermos algumas
                verificações.
              </div>

              <div class="d-flex justify-content-center">
                <button
                  type="submit"
                  class="
                    btn btn-primary btn-lg
                    margin-y1
                    d-flex
                    justify-content-center
                  "
                  :disabled="loading"
                >
                  Continuar
                  <i class="bi bi-arrow-right" v-show="!loading"></i>
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                    v-show="loading"
                  >
                  </span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>

    <s-rodape />
  </div>
</template>
<script>
import { TheMask } from "vue-the-mask";
export default {
  metaInfo: {
    title: "Identificação",
  },
  components: { TheMask },

  data: function () {
    return {
      loading: false,
      campos: {
        cnpj: null,
      },
    };
  },

  methods: {
    verificar: function () {
      var $this = this;
      this.loading = true;

      this.$sdk.get("/cnpj", { cnpj: this.campos.cnpj }, (data) => {
        if (data.status == 200) {
          this.$store.commit("set_empresa", { data: data.data });
          localStorage.setItem("turismo_empresa", JSON.stringify(data.data));

          this.$router.push({ name: "dashboard" });
        } else {
          this.$swal({
            title: "Você ainda não pode solicitar os seus selos",
            text: "Infelizmente você ainda não poderá acessar o nosso portal pois o seu CNPJ ainda não está cadastrado na CADASTUR.<br><br>Clique no botão abaixo e solicite o seu cadastro.",
            type: "warning",
            icon: "warning",
            confirmButtonText: "Ir ao CADASTUR agora",
          }).then(function () {
            $this.loading = false;
            document.location.href =
              "https://cadastur.turismo.gov.br/hotsite/#!/public/capa/entrar";
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
section#identificar {
  input#cnpj {
    font-size: 28px;
    padding-top: 2rem;
  }

  .btn span {
    margin-left: 8px;
    margin-top: 6px;
  }

  .btn i::before {
    margin-left: 7px;
    margin-top: 5px;
  }
}
</style>